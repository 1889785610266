// @flow

import * as React from 'react'

import withTheme from 'hoc/withTheme'
import themes from './themes/__supportedThemes'

type Props = {
    width: string,
    height: string,
    x: string,
    y: string,
    className?: string,
}

const VoucherEventLogo = React.forwardRef(function (props: Props, ref: React.Ref) {
    const { x, y } = props
    const { width } = props
    return (
        <svg
            className={props.className}
            x={x}
            y={y}
            width={width}
            viewBox="0 0 884.13 450.14" ref={ref}>
            <defs>
                <linearGradient id="bf9f29dc-6980-4c63-842b-66ba85363f1c" x1="737.3" y1="317.23" x2="884.13" y2="317.23" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#e77e25"/>
                    <stop offset="0.11" stopColor="#ec8822"/>
                    <stop offset="0.34" stopColor="#f4971e"/>
                    <stop offset="0.61" stopColor="#f9a01c"/>
                    <stop offset="1" stopColor="#faa31b"/>
                </linearGradient>
                <linearGradient id="bc5109a7-736c-410c-8466-62cff76b2c83" y1="344.06" x2="146.82" y2="344.06" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#faa31b"/>
                    <stop offset="0.39" stopColor="#f9a01c"/>
                    <stop offset="0.66" stopColor="#f4971e"/>
                    <stop offset="0.89" stopColor="#ec8822"/>
                    <stop offset="1" stopColor="#e77e25"/>
                </linearGradient>
                <linearGradient id="a7bf12de-f37c-4ce2-8dd3-906d3fddf7a3" x1="59.71" y1="513.23" x2="868.45" y2="200.64" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f8f5b5"/>
                    <stop offset="0.03" stopColor="#ffce47"/>
                    <stop offset="0.16" stopColor="#faa31b"/>
                    <stop offset="0.31" stopColor="#faa31a"/>
                    <stop offset="0.52" stopColor="#fdb814"/>
                    <stop offset="1" stopColor="#f99f1b"/>
                </linearGradient>
                <linearGradient id="bb87385c-bc10-4659-b4e1-cad6d4748167" x1="6965.67" y1="-1282.54" x2="3817.08" y2="-1353.52" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f6851f"/>
                    <stop offset="0.03" stopColor="#fcb017"/>
                    <stop offset="0.14" stopColor="#fec11b"/>
                    <stop offset="0.45" stopColor="#fdb814"/>
                    <stop offset="1" stopColor="#f8971d"/>
                </linearGradient>
                <linearGradient id="e838078e-baaa-470e-b073-12be67e695ed" x1="574.32" y1="-1308.48" x2="3599.94" y2="-1318.7" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" xlinkHref="#a7bf12de-f37c-4ce2-8dd3-906d3fddf7a3"/>
                <linearGradient id="baf77692-d603-4e4a-9697-dbe0af5988ef" x1="2975.63" y1="-1736.12" x2="3659.72" y2="-1342.15" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#ffd355"/>
                    <stop offset="0.38" stopColor="#faa71a"/>
                    <stop offset="0.55" stopColor="#fdb814"/>
                    <stop offset="0.72" stopColor="#ffd253"/>
                    <stop offset="0.83" stopColor="#ffd255"/>
                    <stop offset="1" stopColor="#f6851f"/>
                </linearGradient>
                <linearGradient id="aa4a436d-3adb-438b-92ef-49bcbfd1554a" x1="4464.36" y1="-980.62" x2="3787.71" y2="-1233.3" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#a14322"/>
                    <stop offset="0.08" stopColor="#cd5f27"/>
                    <stop offset="0.3" stopColor="#eb7723"/>
                    <stop offset="0.67" stopColor="#bd5527"/>
                    <stop offset="1" stopColor="#411210"/>
                </linearGradient>
                <linearGradient id="a1edd91f-c14e-4e36-b94b-c16e4e6787b7" x1="4413.69" y1="-888.3" x2="3813.12" y2="-1332.14" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f99f1b"/>
                    <stop offset="0.09" stopColor="#ffcb3a"/>
                    <stop offset="0.2" stopColor="#fcdf75"/>
                    <stop offset="0.33" stopColor="#ffc632"/>
                    <stop offset="0.48" stopColor="#faa31a"/>
                    <stop offset="0.62" stopColor="#fdb814"/>
                    <stop offset="0.77" stopColor="#ffd255"/>
                    <stop offset="1" stopColor="#f6851f"/>
                </linearGradient>
                <linearGradient id="bc1bd72a-f487-43d6-b963-9c4f2fc7b349" x1="3020.64" y1="-927.65" x2="3553.13" y2="-1371.62" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#a14322"/>
                    <stop offset="0.21" stopColor="#cd5f27"/>
                    <stop offset="0.47" stopColor="#eb7723"/>
                    <stop offset="0.67" stopColor="#bd5527"/>
                    <stop offset="1" stopColor="#411210"/>
                </linearGradient>
                <linearGradient id="fb4f0c36-3857-45b2-a5cc-be4a90fdfa8b" x1="3022.68" y1="-906.73" x2="3602.79" y2="-1351.74" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f99f1b"/>
                    <stop offset="0.07" stopColor="#ffcb3a"/>
                    <stop offset="0.18" stopColor="#ffd75f"/>
                    <stop offset="0.27" stopColor="#ffc934"/>
                    <stop offset="0.49" stopColor="#faa31a"/>
                    <stop offset="0.71" stopColor="#fdb814"/>
                    <stop offset="0.84" stopColor="#ffd75f"/>
                    <stop offset="1" stopColor="#f6851f"/>
                </linearGradient>
                <linearGradient id="a62c07e5-22f4-4589-b76c-21f479ab3336" x1="4400.31" y1="-1725.6" x2="3801.42" y2="-1312.72" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#ffd75f"/>
                    <stop offset="0.4" stopColor="#faa31a"/>
                    <stop offset="0.55" stopColor="#fdb814"/>
                    <stop offset="0.67" stopColor="#ffd14f"/>
                    <stop offset="0.79" stopColor="#ffd255"/>
                    <stop offset="1" stopColor="#f6851f"/>
                </linearGradient>
                <linearGradient id="aabf5e79-007f-48d9-b60b-42769324c3ea" x1="4342.35" y1="-1801.49" x2="4367.48" y2="-1725.91" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#f8991d"/>
                    <stop offset="0.35" stopColor="#f7931e"/>
                    <stop offset="1" stopColor="#f78f1e"/>
                </linearGradient>
                <linearGradient id="b798159d-df65-45d5-b9ec-bd665645ca5c" x1="3667.45" y1="-1240.61" x2="3711.35" y2="-1363.88" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#fddb6c"/>
                    <stop offset="0.09" stopColor="#fcdf75"/>
                    <stop offset="0.18" stopColor="#fce485"/>
                    <stop offset="0.44" stopColor="#fdb515"/>
                    <stop offset="0.74" stopColor="#fec125"/>
                    <stop offset="0.92" stopColor="#ffc431"/>
                    <stop offset="1" stopColor="#f8971d"/>
                </linearGradient>
                <linearGradient id="b0ae4ec3-5689-414a-8466-505761074efb" x1="3667.17" y1="-1240.7" x2="3711.07" y2="-1363.97" gradientTransform="matrix(0.11, 0, 0, -0.11, 19.56, -14.06)" gradientUnits="userSpaceOnUse">
                    <stop offset="0" stopColor="#ffd359"/>
                    <stop offset="0.09" stopColor="#ffd75f"/>
                    <stop offset="0.18" stopColor="#fed96a"/>
                    <stop offset="0.44" stopColor="#faa31a"/>
                    <stop offset="0.67" stopColor="#fdb814"/>
                    <stop offset="0.86" stopColor="#fcb216"/>
                    <stop offset="1" stopColor="#e06b26"/>
                </linearGradient>
                <linearGradient id="a18d2d0c-d74d-49cf-9f1a-eb9448e991aa" x1="3725.43" y1="-1223.69" x2="3713.54" y2="-1391.53" xlinkHref="#b798159d-df65-45d5-b9ec-bd665645ca5c"/>
                <linearGradient id="e8ef3103-d01e-40e2-b81d-d11082d2934c" x1="3724.97" y1="-1223.67" x2="3713.07" y2="-1391.5" xlinkHref="#b0ae4ec3-5689-414a-8466-505761074efb"/>
            </defs>
            <title>Asset 1</title>
            <g style={{isolation:'isolate'}}>
                <g id="e503c97e-589c-4c2c-9159-c21af4043dac" data-name="Layer 2">
                    <g id="ec282ecf-0753-487f-a96c-47cb965ffbdf" data-name="Layer 1">
                        <polygon points="737.3 245.44 884.13 240.09 824.55 316.73 884.13 389.02 737.3 394.37 737.3 245.44" style={{fill:'url(#bf9f29dc-6980-4c63-842b-66ba85363f1c)'}}/>
                        <polygon points="796.88 280.5 737.3 282.67 737.3 245.44 796.88 280.5" style={{fill:'#b9691d'}}/>
                        <polygon points="146.82 266.92 0 272.27 59.58 344.56 0 421.2 146.82 415.86 146.82 266.92" style={{fill:'url(#bc5109a7-736c-410c-8466-62cff76b2c83)'}}/>
                        <polygon points="87.5 306.31 147.08 304.15 147.08 266.92 87.5 306.31" style={{fill:'#b9691d'}}/>
                        <path d="M104.17,152.42h74.65v33.13H161.18l9.68,53.56c1.5,8.6,2.14,18.28,2.14,18.28h.87a161.79,161.79,0,0,1,2.8-18.28l19.78-86.69h35.07l20,86.69a161.79,161.79,0,0,1,2.8,18.28h.85a164.4,164.4,0,0,1,2-18.28L267,185.55H249.16V152.42H324v33.13H312l-29,121.53H232.16L217.32,241.9a158,158,0,0,1-2.8-19.35h-.86a181.47,181.47,0,0,1-3,19.35L195.8,307.08H145L116.22,185.55h-12Z" style={{fill:props.primaryColor}}/>
                        <path d="M337.76,274h16.35V185.55H337.76V152.42H460.37v50.12H421.65v-14.2H400.57v22.8h40.87v36.14H400.57v23.88H423.8V257.39h38.72v49.69H337.76Z" style={{fill:props.primaryColor}}/>
                        <path d="M482.3,274h16.35V185.55H482.3V152.42H604.91v50.12H566.19v-14.2H545.12v22.8H586v36.14H545.12v23.88h23.23V257.39h38.72v49.69H482.3Z" style={{fill:props.primaryColor}}/>
                        <path d="M626.85,274h16.34V185.55H626.85V152.42h70.33v33.13h-7.53v25.38h9.9l17.64-25.38h-9.47V152.42h67.76v33.13h-12L733.75,224.7v.43s7.74,3.23,13.33,15.49L760,269.22c1.51,3.23,3.88,4.74,8.82,4.74h6.45v33.12h-31.4c-12.91,0-18.5-3.87-21.94-11.4L701.7,251.37c-1.72-3.66-4.73-4.3-10.32-4.3h-1.73V274h14v33.12H626.85Z" style={{fill:props.primaryColor}}/>
                        <g style={{opacity:0.55,mixBlendMode:'multiply'}}>
                            <polygon points="703.63 278.72 626.85 281.51 626.85 307.08 703.63 307.08 703.63 278.72" style={{fill:'#e6e7e8'}}/>
                            <path d="M714,278.34l7.92,17.34c3.44,7.53,9,11.4,21.94,11.4h31.4v-31Z" style={{fill:'#e6e7e8'}}/>
                        </g>
                        <polygon points="482.3 286.77 482.3 307.08 607.07 307.08 607.07 282.23 482.3 286.77" style={{fill:'#e6e7e8',opacity:0.55,mixBlendMode:'multiply'}}/>
                        <polygon points="337.76 292.03 337.76 307.08 462.52 307.08 462.52 287.49 337.76 292.03" style={{fill:'#e6e7e8',opacity:0.55,mixBlendMode:'multiply'}}/>
                        <g style={{opacity:0.55,mixBlendMode:'multiply'}}>
                            <polygon points="195.81 307.08 198.07 297.12 143.15 299.11 145.04 307.08 195.81 307.08" style={{fill:'#e6e7e8'}}/>
                            <polygon points="229.63 295.97 232.16 307.08 282.93 307.08 286.07 293.91 229.63 295.97" style={{fill:'#e6e7e8'}}/>
                        </g>
                        <path d="M102.46,90.75h12V26.11h-12V1.89h53.47c9.28,0,14.47,0,21.08,1.88,13.21,4.09,21.86,14.16,21.86,27.84,0,9.12-4.24,19-13.21,24.07V56c14.15,4.4,17.77,15.41,17.77,25.32,0,18.09-13.05,29.25-27.21,32.4C170.09,115,165.53,115,159.4,115H102.46Zm55.05-46.87c5.5,0,7.7-3.62,7.7-7.86,0-4.57-2.36-7.87-8.18-7.87h-8.65V43.88Zm3,44.82c6.29,0,9.12-4.24,9.12-9.75,0-5-2.36-8.65-8.81-8.65H148.38V88.7Z" style={{fill:props.primaryColor}}/>
                        <path d="M272.69,0c35.23,0,60.08,25.32,60.08,57.72,0,33.19-24.85,59.14-60.08,59.14s-60.08-25.95-60.08-59.14C212.61,25.32,237.46,0,272.69,0Zm0,87.6c13.69,0,25-12.73,25-29.88,0-16.36-11.32-28.47-25-28.47s-25,12.11-25,28.47C247.69,74.87,259,87.6,272.69,87.6Z" style={{fill:props.primaryColor}}/>
                        <path d="M340.39,90.75h8.18l27.37-33.82L350.3,26.11h-9.12V1.89h50.64V26.11h-7.23l7.07,8.49a57.53,57.53,0,0,1,3.94,5.66h.31a67.14,67.14,0,0,1,3.93-5.66l6.76-8.49h-7.07V1.89H447V26.11h-8.18l-27.05,33,26.42,31.62h8.49V115h-50.8V90.75h8l-7.86-9.44a57.53,57.53,0,0,1-3.94-5.66h-.31s-1.57,2.51-3.93,5.66l-7.08,9.44h7.55V115h-48Z" style={{fill:props.primaryColor}}/>
                        <path d="M459.51,90.75h12V26.11h-12V1.89h57.88V26.11H505.44V90.75h11.95V115H459.51Z" style={{fill:props.primaryColor}}/>
                        <path d="M532.53,90.75h12V26.11h-12V1.89h46.56L605.2,52.06a97.62,97.62,0,0,1,5.18,13h.79A139.55,139.55,0,0,1,610.07,51V26.11H597.33V1.89H656V26.11H644V115H609.76L583.34,65.11a90.62,90.62,0,0,1-5.2-13h-.78a139.87,139.87,0,0,1,1.1,14.31V90.75H591.2V115H532.53Z" style={{fill:props.primaryColor}}/>
                        <path d="M726.06,0c20.6,0,46.39,7.86,46.39,22V42.47H742.26V34.28c0-4.08-5.5-6-14-6C710,28.31,699.8,36.65,699.8,56c0,18.88,9.9,32.56,30.66,32.56,5.82,0,14.32-1.1,14.32-5.51v-4.4H728.42V54.41H775V94.68c0,16.67-33.19,22.18-49.39,22.18-34.92,0-60.87-25-60.87-59.46C664.72,23.28,690.36,0,726.06,0Z" style={{fill:props.primaryColor}}/>
                        <path d="M795.42,280.66,89,306.37a6.26,6.26,0,0,0-6,6.22V444.35a5.73,5.73,0,0,0,6,5.78l706.45-25.7a6.27,6.27,0,0,0,6-6.22V286.44A5.74,5.74,0,0,0,795.42,280.66Z" style={{fill:'url(#a7bf12de-f37c-4ce2-8dd3-906d3fddf7a3)'}}/>
                        <g style={{opacity:0.2,mixBlendMode:'multiply'}}>
                            <path d="M184.76,407.66l16.37-20.1c7.8,6,19.49,10.48,33.53,10,5.92-.21,11.54-1.72,11.54-5.1,0-8.32-58,2.24-58-31.93,0-14.69,14.66-29.26,43.19-30.29,17-.62,32.28,2.85,44.13,10.48l-16.84,19.19c-9-5.39-20.73-7.82-30.56-7.46-6.24.22-8,2-8,4.18,0,8.19,57.85-.93,57.85,31.3,0,19.1-17,30.76-44.91,31.78C211,420.47,195.83,415.57,184.76,407.66Z" style={{fill:'#b35126'}}/>
                            <path d="M403,411.93l-3.75-10-37.42,1.36-3.74,10.27-35.71,1.3,38.36-88.07,39.61-1.44,38.36,85.28ZM380.52,351.8l-10.3,28.32,20.59-.75Z" style={{fill:'#b35126'}}/>
                            <path d="M491.13,408.72V322l31.5-1.14v63.8l39.45-1.44v22.88Z" style={{fill:'#b35126'}}/>
                            <path d="M621.55,404V317.3l78.9-2.87V337.3L653.05,339v8.58l46.31-1.69v22.87l-46.31,1.69V380l47.4-1.72V401.1Z" style={{fill:'#b35126'}}/>
                        </g>
                        <path d="M190.24,403.11l16-19.66c7.63,5.83,19.08,10.25,32.82,9.75,5.8-.21,11.29-1.69,11.29-5,0-8.14-56.78,2.2-56.78-31.25,0-14.38,14.36-28.63,42.28-29.65,16.64-.61,31.6,2.79,43.19,10.26l-16.48,18.78c-8.85-5.27-20.29-7.65-29.91-7.3-6.11.22-7.78,1.94-7.78,4.1,0,8,56.62-.92,56.62,30.62,0,18.7-16.64,30.12-44,31.11C215.88,415.66,201.07,410.86,190.24,403.11Z" style={{fill:'#fff'}}/>
                        <path d="M403.81,407.3l-3.66-9.79-36.63,1.33-3.66,10.05-35,1.28L362.45,324l38.77-1.41L438.76,406Zm-22-58.85-10.08,27.71,20.15-.73Z" style={{fill:'#fff'}}/>
                        <path d="M490.1,404.15V319.32l30.83-1.12v62.45l38.61-1.41v22.39Z" style={{fill:'#fff'}}/>
                        <path d="M617.75,399.51V314.68L695,311.87v22.38l-46.39,1.69v8.39l45.32-1.65v22.39l-45.32,1.65V376L695,374.31V396.7Z" style={{fill:'#fff'}}/>
                        <path d="M450,125.13l346.78-2.28v20.53L451.3,141.12c-10.47-.19-22.37,0-32.66.19L86.6,143V122.33l328.58,2.07c10.79.22,24.06.92,34.83.73Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                        <g id="aa7f680e-899c-46ba-ad78-4c89e1747d6c" data-name=" 2497766600688">
                            <path d="M450.14,125.17c89.55-.74,257.1-1.49,346.65-2.23v20.23c-89.16-.72-256.33-1.45-345.49-2.18-10.47-.19-22.37,0-32.66.18-88.35.55-243.69,1.11-332,1.67V122.46c87.29.68,241.57,1.35,328.86,2,10.79.22,23.91.88,34.68.68Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M450.27,125.21c89.51-.72,257-1.45,346.52-2.17V143c-89.16-.7-256.33-1.4-345.49-2.1-10.48-.19-22.37,0-32.67.15-88.34.55-243.68,1.09-332,1.64V122.58c87.38.67,241.76,1.34,329.14,2,10.79.22,23.77.83,34.53.63Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M450.4,125.26c89.47-.71,256.93-1.42,346.39-2.13v19.63c-89.16-.67-256.33-1.34-345.49-2-10.48-.19-22.37-.07-32.67.13-88.34.53-243.68,1.06-332,1.59V122.71c87.47.66,241.94,1.31,329.42,2,10.79.21,23.62.77,34.38.58Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M450.54,125.3c89.42-.69,256.83-1.38,346.25-2.07v19.33c-89.16-.65-256.33-1.29-345.49-1.94-10.48-.19-22.37-.08-32.67.11-88.34.52-243.68,1-332,1.56V122.84c87.57.64,242.13,1.28,329.7,1.93,10.79.21,23.47.73,34.24.53Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M450.67,125.35c89.37-.68,256.75-1.35,346.12-2v19c-89.16-.61-256.33-1.23-345.49-1.85-10.47-.19-22.37-.11-32.66.08-88.35.51-243.69,1-332,1.53V123c87.66.63,242.32,1.26,330,1.89,10.79.21,23.33.68,34.09.49Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M450.8,125.39c89.33-.66,256.66-1.31,346-2v18.73c-89.16-.59-256.33-1.18-345.49-1.77-10.47-.2-22.37-.14-32.66.06-88.35.49-243.69,1-332,1.49V123.09c87.75.62,242.51,1.24,330.26,1.86,10.79.21,23.18.63,33.94.44Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M450.94,125.43c89.28-.64,256.57-1.28,345.85-1.92v18.43c-89.16-.56-256.33-1.12-345.49-1.69-10.47-.19-22.37-.15-32.66,0-88.35.48-243.69,1-332,1.45V123.22c87.85.61,242.69,1.21,330.54,1.82,10.79.21,23,.59,33.79.39Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M451.07,125.48c89.24-.63,256.48-1.25,345.72-1.87v18.13c-89.16-.54-256.33-1.07-345.49-1.61-10.47-.19-22.37-.18-32.66,0-88.35.48-243.69,1-332,1.42V123.35c87.94.59,242.88,1.19,330.82,1.78,10.79.22,22.88.54,33.65.35Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M451.2,125.52c89.2-.61,256.4-1.21,345.59-1.82v17.83c-89.16-.51-256.33-1-345.49-1.53-10.47-.19-22.37-.2-32.66,0-88.35.46-243.69.92-332,1.38v-17.9c88,.58,243.07,1.16,331.1,1.74,10.79.22,22.74.49,33.5.3Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M451.33,125.57c89.16-.59,256.31-1.18,345.46-1.77v17.53c-89.16-.48-256.33-1-345.49-1.45-10.48-.19-22.37-.22-32.67,0-88.34.45-243.68.9-332,1.35V123.61c88.13.56,243.25,1.13,331.38,1.7,10.79.22,22.59.45,33.35.26Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M451.47,125.61c89.1-.57,256.21-1.15,345.32-1.72v17.23c-89.16-.45-256.33-.91-345.49-1.36-10.48-.19-22.37-.25-32.67,0-88.34.43-243.68.87-332,1.3V123.73c88.22.56,243.44,1.12,331.66,1.67,10.79.22,22.44.4,33.21.21Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                            <path d="M451.6,125.65c89.06-.55,256.13-1.11,345.19-1.66v16.93c-89.16-.43-256.33-.86-345.49-1.29-10.48-.19-22.37-.26-32.67-.07-88.34.42-243.68.85-332,1.27v-17c88.31.54,243.63,1.09,331.94,1.64,10.79.21,22.29.35,33.06.15Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                        </g>
                        <path d="M451.73,125.7l345.06-1.62v16.63l-345.49-1.2c-10.48-.19-22.37-.29-32.67-.1l-332,1.24V124l332.22,1.6c10.79.21,22.15.3,32.91.11Z" style={{fill:'#060606',fillOpacity:0.031373}}/>
                        <path d="M447.12,125.07v14.79l349.67,2.25c3.18.07,2.62-1.57,2.69-2.55a83.26,83.26,0,0,0-.13-14.93c-.12-.8-.26-1.35-2.56-1.33l-349.67,1.77Z" style={{fill:'url(#bb87385c-bc10-4659-b4e1-cad6d4748167)'}}/>
                        <path d="M422.73,139.81V124.25L86.83,123c-3.18-.07-2.62,1.64-2.69,2.68a84.06,84.06,0,0,0,.13,14.93c.12.84.26,1.42,2.56,1.4Z" style={{fill:'url(#e838078e-baaa-470e-b073-12be67e695ed)'}}/>
                        <path d="M86.83,142.07l335.93-1.95-.06-.63L87.56,141.78a6.7,6.7,0,0,1-2.57-.26,6.28,6.28,0,0,1-.59-.31c.36.88,1.45.86,2.43.86Z" style={{fill:'#f78f1e'}}/>
                        <path d="M84.68,123.38a2.26,2.26,0,0,0-.54,1.81,3.84,3.84,0,0,1,0,.48,70.78,70.78,0,0,0-.26,8,44.31,44.31,0,0,0,.35,6.61,5.35,5.35,0,0,0,.15.87c.12.31.88.61,1.84.59-.75-.1-1.1-.3-1.17-.52a1.83,1.83,0,0,1-.17-.67,39.94,39.94,0,0,1-.39-6.88,70.58,70.58,0,0,1,.26-8c0-.15,0-.32,0-.5a2,2,0,0,1,.39-1.51,1.49,1.49,0,0,1,.55-.28,4.21,4.21,0,0,1,1.11-.09l336.07,1.57.07-.62L90.12,122.84c-1.87,0-4.87-.11-5.43.54Z" style={{fill:'#fde284'}}/>
                        <path d="M529.17,103.72c0,1.49-2,5.26-3.84,7.39-15.81,18.38-52.6,29.81-78.59,23.24.75-4.59.13-8.54-2.12-9.77,6.95-1.9,12-7.38,22.13-19,9.46-10.92,25.4-26.11,39.62-29.44,2.28-.53,7.31.05,10.69,5.05,1.15,1.7,9.36,15.32,11.66,19.8a5,5,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                        <g id="a2088e9b-272d-4952-90d5-5238c598403f" data-name=" 2497766607056">
                            <path d="M528.35,103.48c-.05,1.48-2,5.17-3.78,7.26-15.68,18.37-52,29.69-77.83,23.61.75-4.59.13-8.53-2.11-9.76,6.95-1.9,12.3-7.12,22.39-18.78,9.46-10.92,25-25.58,39.18-28.92,2.28-.53,7.12,0,10.39,4.81,1.22,1.69,9,14.6,11.31,19a5,5,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M527.52,103.23c0,1.48-2,5.08-3.71,7.14-15.54,18.36-51.3,29.58-77.07,24,.75-4.59.14-8.52-2.11-9.75,7-1.91,12.56-6.87,22.66-18.52C476.74,95.16,491.81,81,506,77.69a10,10,0,0,1,10.09,4.55c1.29,1.68,8.73,13.88,11,18.21a5.08,5.08,0,0,1,.44,2.78Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M526.7,103c-.05,1.49-1.94,5-3.65,7-15.41,18.35-50.65,29.46-76.31,24.35.75-4.59.14-8.52-2.1-9.75,6.95-1.9,12.82-6.6,22.91-18.25,9.46-10.92,24.09-24.53,38.31-27.86a9.66,9.66,0,0,1,9.78,4.3c1.37,1.67,8.42,13.17,10.61,17.42a4.94,4.94,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M525.88,102.74c0,1.48-1.92,4.89-3.59,6.88C507,128,472.28,139,446.74,134.35c.75-4.59.15-8.51-2.1-9.74,7-1.9,13.09-6.34,23.18-18,9.46-10.91,23.65-24,37.87-27.33a9.33,9.33,0,0,1,9.48,4c1.44,1.67,8.11,12.46,10.26,16.64a4.92,4.92,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M525.05,102.49c0,1.49-1.89,4.8-3.52,6.76-15.15,18.34-49.36,29.24-74.79,25.1.75-4.59.16-8.5-2.09-9.74,7-1.9,13.34-6.07,23.44-17.73C477.55,96,491.3,83.41,505.52,80.07a9,9,0,0,1,9.18,3.81c1.51,1.65,7.79,11.74,9.91,15.84a5,5,0,0,1,.44,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M524.23,102.24c-.05,1.49-1.87,4.72-3.46,6.64-15,18.33-48.71,29.13-74,25.47.75-4.59.16-8.5-2.09-9.73,7-1.9,13.61-5.81,23.71-17.47,9.45-10.91,22.77-23,37-26.28a8.7,8.7,0,0,1,8.88,3.55c1.58,1.65,7.47,11,9.55,15a4.94,4.94,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M523.41,102c0,1.48-1.85,4.62-3.4,6.51-14.88,18.32-48.06,29-73.27,25.84.75-4.59.17-8.49-2.08-9.72,6.95-1.9,13.87-5.55,24-17.21C478.08,96.51,491,85,505.18,81.67a8.34,8.34,0,0,1,8.57,3.3c1.66,1.64,7.17,10.32,9.21,14.26a4.92,4.92,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M522.58,101.75c0,1.49-1.82,4.53-3.33,6.39-14.75,18.31-47.42,28.9-72.51,26.21.75-4.59.17-8.49-2.07-9.72,6.95-1.9,14.13-5.28,24.22-16.94,9.46-10.92,21.9-21.9,36.12-25.23a8,8,0,0,1,8.27,3.05c1.73,1.63,6.85,9.6,8.86,13.47a5,5,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M521.76,101.5c0,1.49-1.8,4.44-3.27,6.26-14.61,18.3-46.77,28.79-71.75,26.59.75-4.59.18-8.48-2.07-9.71,7-1.9,14.39-5,24.49-16.68,9.46-10.92,21.46-21.37,35.68-24.71a7.76,7.76,0,0,1,8,2.81c1.81,1.62,6.54,8.89,8.51,12.67a5,5,0,0,1,.44,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M520.94,101.26c-.05,1.48-1.78,4.35-3.21,6.13-14.48,18.29-46.12,28.68-71,27,.75-4.59.18-8.47-2.06-9.7,6.95-1.9,14.65-4.76,24.75-16.42,9.45-10.92,21-20.85,35.24-24.18a7.47,7.47,0,0,1,7.67,2.55c1.88,1.61,6.22,8.18,8.15,11.89a4.9,4.9,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M520.12,101c0,1.49-1.76,4.26-3.15,6-14.35,18.28-45.47,28.56-70.23,27.33.75-4.59.19-8.47-2.06-9.7,7-1.9,14.92-4.5,25-16.15,9.45-10.92,20.57-20.32,34.8-23.66a7.18,7.18,0,0,1,7.36,2.31c2,1.6,5.91,7.46,7.81,11.09a5,5,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M519.29,100.77c0,1.48-1.73,4.16-3.09,5.88-14.21,18.27-44.82,28.45-69.46,27.7.75-4.59.19-8.46-2.05-9.69,6.95-1.9,15.18-4.24,25.27-15.89,9.46-10.92,20.14-19.8,34.36-23.13a6.93,6.93,0,0,1,7.07,2c2,1.6,5.59,6.75,7.46,10.3a5,5,0,0,1,.44,2.78Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M518.47,100.52c-.05,1.49-1.71,4.07-3,5.76-14.08,18.26-44.18,28.33-68.71,28.07.75-4.59.2-8.45-2.05-9.68,7-1.9,15.44-4,25.54-15.64,9.45-10.91,19.7-19.26,33.92-22.6a6.69,6.69,0,0,1,6.77,1.81c2.1,1.58,5.28,6,7.1,9.51a4.85,4.85,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M517.65,100.27c0,1.49-1.69,4-3,5.64-14,18.25-43.53,28.21-68,28.44.75-4.59.21-8.45-2-9.68,6.95-1.9,15.7-3.71,25.8-15.37C480,98.39,489.76,90.56,504,87.23a6.48,6.48,0,0,1,6.47,1.55c2.17,1.58,5,5.32,6.75,8.72a5,5,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M516.83,100c-.05,1.48-1.66,3.89-2.9,5.5-13.82,18.25-42.88,28.11-67.19,28.82.75-4.59.21-8.44-2-9.67,7-1.9,16-3.45,26.06-15.11,9.45-10.92,18.82-18.21,33-21.55,2.28-.53,4.45-.36,6.17,1.3a23.52,23.52,0,0,1,6.4,7.93,5,5,0,0,1,.44,2.78Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M516,99.78c0,1.49-1.63,3.8-2.83,5.38-13.69,18.24-42.24,28-66.43,29.19.75-4.59.22-8.43-2-9.66,7-1.9,16.23-3.19,26.32-14.85,9.46-10.92,18.39-17.69,32.61-21,2.28-.53,4.26-.39,5.86,1.05A19.12,19.12,0,0,1,515.56,97a5,5,0,0,1,.44,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M515.18,99.53c0,1.49-1.61,3.71-2.77,5.26-13.55,18.23-41.59,27.88-65.67,29.56.75-4.59.22-8.43-2-9.66,6.95-1.9,16.48-2.92,26.58-14.58,9.45-10.92,17.95-17.16,32.17-20.5,2.28-.53,4.06-.41,5.56.8a15.44,15.44,0,0,1,5.7,6.35,4.85,4.85,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M514.36,99.29c-.05,1.48-1.59,3.61-2.71,5.13-13.42,18.22-40.94,27.76-64.91,29.93.75-4.59.23-8.42-2-9.65,7-1.9,16.75-2.67,26.85-14.32,9.45-10.92,17.51-16.64,31.73-20,2.28-.54,3.87-.45,5.26.55a12.22,12.22,0,0,1,5.35,5.55,4.87,4.87,0,0,1,.45,2.78Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                        </g>
                        <path d="M513.53,99c0,1.49-1.56,3.52-2.65,5-13.28,18.21-40.29,27.65-64.14,30.3.75-4.59.23-8.42-2-9.65,6.95-1.9,17-2.4,27.1-14.06,9.46-10.91,17.08-16.1,31.3-19.44,2.27-.53,3.68-.47,4.95.3,2.54,1.54,3.4,1.76,5,4.77a4.83,4.83,0,0,1,.45,2.77Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                        <path d="M796.79,123.24l-349.7,1.53.06.6,348.92-1.85a6.88,6.88,0,0,1,2.56.24,6.37,6.37,0,0,1,.6.3c-.37-.84-1.46-.82-2.44-.82Z" style={{fill:'#fde284'}}/>
                        <path d="M798.94,141.74a2,2,0,0,0,.54-1.72c0-.16,0-.31,0-.45.19-2.5.28-4.33.25-8.7a35.58,35.58,0,0,0-.34-6,4.11,4.11,0,0,0-.16-.82c-.11-.29-.87-.58-1.83-.56.74.1,1.1.28,1.16.49a1.86,1.86,0,0,1,.18.64,31.15,31.15,0,0,1,.38,6.22c0,4.36-.06,6.18-.25,8.68,0,.15,0,.31,0,.47a1.72,1.72,0,0,1-.39,1.43,1.18,1.18,0,0,1-.55.27,4.21,4.21,0,0,1-1.11.09L447,139.25l-.06.59,346.6,2.42c1.87.05,4.86.1,5.43-.52Z" style={{fill:'#f78f1e'}}/>
                        <path d="M360.5,198.48c-7.8-10-6.22-18.84-11.85-24.44,13.54-5.49,28.44-26.61,37.61-33.12,8.83-6.27,20-8.71,41.18-11.68.51-3.69,1.65-6.83,3.68-6.61L443.45,124c2,.21,2.41,3.38,2.31,7,5.29.2,11.54,0,17.57,1.17,24.66,4.73,29.81,36.26,52.7,46.11,4-1.89,4.77-2.32,5.68-3.88,1.75,12-5.88,15.73-14.76,18.31-3.25.95-8.62.61-12-.34-3-.83-8.79-5.4-10.91-7.41-12.85-12.18-20.83-37.46-38.18-42.47-.59-.18-1.53.92-4.73.88a85.44,85.44,0,0,1-11.19-.58,17,17,0,0,0-2.92-.16c-14.79,2.52-33.55,30.25-44.73,44.71-4.7,6.08-18.52,15.35-21.79,11.16Z" style={{fill:'#171717',fillOpacity:0.019608}}/>
                        <g id="b16fd331-64a1-4b6f-ae85-ba4485397ffb" data-name=" 2497766617328">
                            <path d="M360.91,197.22c-7.63-9.57-6.18-18.21-11.37-23.71,13.13-5.57,27.62-25.89,36.79-32.4,8.83-6.27,19.93-8.8,41.1-11.87.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.42,3.39,2.31,7,5.3.2,11.54,0,17.58,1.17,24.7,4.71,29.23,36.18,52.12,46,3.88-1.79,4.64-2.22,5.6-3.75,1.57,11.51-5.79,15-14.28,17.48-3.25.89-8.43.55-11.75-.41-3-.87-8.9-5.44-11-7.45-12.85-12.18-20-36.95-38.11-41.72-.75-.18-1.61,1-4.66.94a94.3,94.3,0,0,1-11.39-.63,16.3,16.3,0,0,0-2.78-.17c-15.28,2.52-33.51,29.4-44.76,43.76-4.74,6-18,15.07-21.4,11Z" style={{fill:'#141414',fillOpacity:0.019608}}/>
                            <path d="M361.31,196c-7.45-9.14-6.13-17.59-10.87-23,12.71-5.65,26.78-25.18,36-31.7,8.83-6.27,19.9-8.89,41-12,.51-3.7,1.65-6.83,3.68-6.61L443.45,124c2,.22,2.43,3.39,2.33,7,5.29.2,11.53,0,17.57,1.17,24.74,4.69,28.65,36.11,51.55,46,3.77-1.69,4.51-2.13,5.51-3.63,1.39,11-5.69,14.34-13.79,16.65a24.81,24.81,0,0,1-11.51-.48c-2.95-.89-9-5.48-11.14-7.49-12.85-12.18-19.21-36.43-38-41-.9-.18-1.69,1-4.59,1a100,100,0,0,1-11.6-.69,15.94,15.94,0,0,0-2.64-.16c-15.76,2.51-33.45,28.54-44.78,42.81-4.78,6-17.51,14.78-21,10.8Z" style={{fill:'#0f0f10',fillOpacity:0.019608}}/>
                            <path d="M361.72,194.72c-7.28-8.7-6.09-17-10.38-22.26,12.3-5.72,26-24.47,35.12-31,8.83-6.27,19.89-9,41-12.24.51-3.69,1.65-6.83,3.68-6.61L443.45,124c2,.21,2.44,3.39,2.33,7,5.3.2,11.54,0,17.58,1.17,24.78,4.68,28.07,36,51,45.9,3.67-1.59,4.39-2,5.43-3.51,1.22,10.48-5.59,13.66-13.3,15.83a24.25,24.25,0,0,1-11.26-.55c-2.95-.93-9.14-5.52-11.26-7.53-12.85-12.18-18.4-35.91-38-40.21-1.06-.18-1.76,1.1-4.51,1.07a113.81,113.81,0,0,1-11.81-.75,16.47,16.47,0,0,0-2.5-.17c-16.25,2.51-33.41,27.69-44.81,41.86-4.81,6-17,14.5-20.63,10.63Z" style={{fill:'#090909',fillOpacity:0.019608}}/>
                            <path d="M362.13,193.47c-7.11-8.27-6-16.34-9.89-21.54,11.88-5.79,25.12-23.75,34.29-30.26,8.83-6.27,19.86-9.09,40.9-12.43.52-3.69,1.66-6.83,3.69-6.61L443.45,124c2,.21,2.45,3.39,2.34,7,5.3.2,11.54,0,17.58,1.17,24.82,4.66,27.49,36,50.39,45.83,3.56-1.49,4.27-1.93,5.35-3.38,1,10-5.5,13-12.82,15a23.83,23.83,0,0,1-11-.62c-2.93-1-9.26-5.56-11.38-7.57C471.05,169.23,466.31,146,446,142c-1.21-.18-1.83,1.16-4.43,1.13-5.13-.16-6.91-.26-12-.8a17.19,17.19,0,0,0-2.36-.18c-16.74,2.51-33.36,26.83-44.83,40.91-4.85,6-16.49,14.22-20.24,10.46Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M362.53,192.22c-6.93-7.84-6-15.71-9.39-20.81,11.46-5.88,24.28-23,33.46-29.56,8.82-6.27,19.83-9.17,40.84-12.61.51-3.7,1.65-6.83,3.68-6.61L443.45,124c2,.22,2.46,3.39,2.35,7,5.3.2,11.53,0,17.57,1.17,24.86,4.64,26.92,35.9,49.82,45.76,3.46-1.39,4.15-1.83,5.27-3.26.86,9.45-5.41,12.27-12.34,14.17a23.21,23.21,0,0,1-10.75-.69c-2.92-1-9.39-5.6-11.51-7.61-12.85-12.18-16.77-34.88-37.81-38.69-1.36-.19-1.91,1.21-4.36,1.18-5.13-.19-7.12-.31-12.22-.86a17,17,0,0,0-2.23-.18c-17.22,2.51-33.31,26-44.85,40-4.89,5.92-16,13.93-19.86,10.28Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M362.94,191c-6.76-7.39-5.95-15.08-8.9-20.08,11-5.95,23.45-22.33,32.62-28.84,8.83-6.27,19.81-9.27,40.77-12.8.52-3.69,1.66-6.83,3.69-6.61L443.45,124c2,.21,2.46,3.39,2.36,7,5.3.2,11.53.05,17.57,1.17,24.9,4.62,26.34,35.84,49.24,45.69,3.36-1.29,4-1.74,5.19-3.13.68,8.93-5.31,11.58-11.85,13.34a22.67,22.67,0,0,1-10.51-.76c-2.91-1-9.5-5.64-11.62-7.65-12.85-12.18-16-34.37-37.74-37.94-1.52-.18-2,1.28-4.29,1.24-5.12-.21-7.32-.36-12.43-.91a16.73,16.73,0,0,0-2.09-.18c-17.7,2.5-33.26,25.12-44.87,39-4.93,5.89-15.48,13.65-19.47,10.11Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M363.34,189.71c-6.58-7-5.9-14.46-8.41-19.36,10.64-6,22.63-21.61,31.8-28.13,8.83-6.27,19.79-9.36,40.7-13,.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.47,3.39,2.37,7,5.29.2,11.53.06,17.57,1.17,24.94,4.6,25.77,35.77,48.66,45.62,3.25-1.19,3.91-1.64,5.11-3,.5,8.42-5.21,10.89-11.36,12.51a22.11,22.11,0,0,1-10.27-.82c-2.89-1.06-9.62-5.68-11.74-7.69-12.85-12.18-15.15-33.85-37.66-37.19-1.68-.19-2.07,1.34-4.21,1.3-5.13-.24-7.54-.42-12.64-1a18.8,18.8,0,0,0-1.95-.19c-18.2,2.5-33.21,24.27-44.9,38.07-5,5.85-15,13.36-19.09,9.92Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M363.75,188.46c-6.41-6.53-5.86-13.83-7.92-18.64,10.22-6.1,21.79-20.9,31-27.41,8.83-6.27,19.77-9.46,40.64-13.17.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.48,3.39,2.37,7,5.3.2,11.53.06,17.58,1.17,25,4.58,25.19,35.7,48.08,45.55,3.15-1.09,3.79-1.54,5-2.89.32,7.91-5.12,10.2-10.88,11.69a21.59,21.59,0,0,1-10-.9c-2.89-1.08-9.75-5.72-11.87-7.73-12.85-12.17-14.33-33.33-37.58-36.43-1.83-.19-2.15,1.4-4.14,1.36-5.13-.26-7.74-.47-12.85-1a15.88,15.88,0,0,0-1.81-.2c-18.68,2.5-33.16,23.42-44.92,37.12-5,5.82-14.47,13.09-18.7,9.75Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M364.16,187.21c-6.24-6.09-5.82-13.21-7.43-17.91,9.81-6.18,21-20.19,30.13-26.7,8.83-6.27,19.74-9.55,40.57-13.36.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.49,3.4,2.38,7,5.3.2,11.53.07,17.58,1.17,25,4.57,24.61,35.63,47.5,45.48,3.05-1,3.67-1.44,4.95-2.76.14,7.39-5,9.51-10.39,10.85a20.88,20.88,0,0,1-9.77-1c-2.87-1.11-9.86-5.76-12-7.77-12.84-12.18-13.51-32.82-37.51-35.67-2-.19-2.22,1.45-4.06,1.42-5.12-.29-7.95-.53-13.05-1.08-.1,0-1.26-.19-1.68-.19C408.25,144,394.3,164,382.47,177.64c-5,5.78-14,12.79-18.31,9.57Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M364.56,186c-6.06-5.65-5.77-12.58-6.93-17.18,9.39-6.25,20.12-19.47,29.29-26,8.83-6.27,19.73-9.64,40.51-13.54.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.5,3.4,2.39,7,5.3.2,11.53.08,17.57,1.17,25.06,4.55,24,35.56,46.93,45.41,2.94-.88,3.54-1.35,4.87-2.64,0,6.88-4.93,8.82-9.91,10a20.25,20.25,0,0,1-9.52-1,61.27,61.27,0,0,1-12.1-7.81c-12.85-12.17-12.7-32.3-37.44-34.91-2.14-.2-2.29,1.51-4,1.48-5.11-.32-8.15-.59-13.26-1.13-.1,0-1.15-.19-1.53-.2-19.65,2.48-33.07,21.7-45,35.22-5.08,5.75-13.45,12.51-17.93,9.39Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M365,184.7c-5.88-5.22-5.72-11.95-6.44-16.46,9-6.33,19.29-18.76,28.46-25.27,8.83-6.27,19.7-9.74,40.45-13.73.51-3.7,1.65-6.83,3.68-6.61L443.45,124c2,.22,2.51,3.4,2.4,7,5.3.2,11.53.08,17.58,1.17,25.09,4.53,23.45,35.49,46.35,45.34,2.83-.78,3.41-1.25,4.79-2.52-.22,6.37-4.84,8.13-9.43,9.21a19.71,19.71,0,0,1-9.27-1.11,64.44,64.44,0,0,1-12.23-7.85c-12.84-12.17-11.89-31.78-37.35-34.16-2.3-.2-2.38,1.57-3.92,1.54-5.12-.34-8.36-.64-13.47-1.18-.1,0-1-.18-1.4-.21-20.13,2.48-33,20.85-45,34.27-5.12,5.73-12.94,12.24-17.54,9.22Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M365.38,183.45c-5.71-4.78-5.68-11.33-5.95-15.74,8.56-6.4,18.46-18,27.63-24.55,8.83-6.27,19.67-9.83,40.37-13.92.52-3.69,1.66-6.83,3.69-6.61L443.45,124c2,.21,2.52,3.39,2.41,7,5.3.2,11.52.09,17.57,1.18,25.14,4.5,22.88,35.41,45.78,45.26a8.58,8.58,0,0,0,4.71-2.39c-.4,5.85-4.75,7.44-8.94,8.37a19.08,19.08,0,0,1-9-1.17,67.88,67.88,0,0,1-12.34-7.89c-12.85-12.18-11.08-31.27-37.29-33.4-2.45-.2-2.45,1.63-3.84,1.59-5.12-.36-8.57-.69-13.68-1.23-.1,0-.94-.17-1.26-.21-20.62,2.48-33,20-45,33.32-5.16,5.69-12.44,12-17.15,9Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M365.78,182.2c-5.53-4.35-5.63-10.7-5.46-15,8.15-6.47,17.63-17.33,26.81-23.84,8.82-6.27,19.65-9.92,40.31-14.1.51-3.7,1.65-6.83,3.68-6.61L443.45,124c2,.22,2.52,3.4,2.42,7,5.3.2,11.52.09,17.57,1.17,25.18,4.49,22.3,35.35,45.2,45.2a8.31,8.31,0,0,0,4.63-2.27c-.58,5.33-4.65,6.75-8.46,7.55a18.37,18.37,0,0,1-8.77-1.25c-2.82-1.24-10.35-5.91-12.47-7.93-12.85-12.17-10.26-30.75-37.21-32.65-2.6-.2-2.53,1.7-3.77,1.66-5.11-.39-8.78-.75-13.88-1.29-.11,0-.84-.16-1.12-.22-21.11,2.48-32.92,19.14-45,32.38-5.19,5.65-11.92,11.66-16.76,8.87Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M366.19,181c-5.36-3.92-5.59-10.08-5-14.29,7.74-6.55,16.8-16.62,26-23.13,8.83-6.27,19.63-10,40.24-14.29.52-3.69,1.66-6.83,3.69-6.61L443.45,124c2,.21,2.53,3.39,2.43,7,5.29.19,11.52.09,17.57,1.17,25.21,4.47,21.72,35.27,44.62,45.12a8,8,0,0,0,4.55-2.14c-.76,4.82-4.56,6.05-8,6.72a17.83,17.83,0,0,1-8.53-1.32c-2.81-1.27-10.46-5.95-12.59-8-12.85-12.18-9.45-30.24-37.13-31.9-2.76-.2-2.61,1.75-3.7,1.72-5.11-.42-9-.8-14.09-1.35a9.36,9.36,0,0,1-1-.22c-21.59,2.48-32.87,18.29-45.07,31.43-5.22,5.62-11.42,11.38-16.37,8.69Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M366.6,179.69c-5.19-3.47-5.55-9.45-4.48-13.56,7.32-6.63,16-15.9,25.14-22.42,8.83-6.27,19.6-10.1,40.17-14.47.52-3.69,1.66-6.83,3.69-6.61L443.45,124c2,.21,2.54,3.39,2.43,7,5.3.19,11.53.1,17.58,1.17,25.25,4.45,21.14,35.2,44,45.05a7.64,7.64,0,0,0,4.47-2c-.93,4.31-4.46,5.37-7.48,5.89a17.09,17.09,0,0,1-8.28-1.38c-2.8-1.3-10.59-6-12.71-8-12.85-12.18-8.64-29.72-37.06-31.14-2.92-.2-2.69,1.81-3.62,1.78-5.11-.45-9.2-.86-14.3-1.41a5.65,5.65,0,0,1-.85-.22c-22.07,2.47-32.81,17.43-45.08,30.48-5.27,5.59-10.92,11.1-16,8.52Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M367,178.44c-5-3-5.5-8.82-4-12.84,6.9-6.7,15.13-15.19,24.3-21.7,8.83-6.27,19.59-10.2,40.11-14.66.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.55,3.4,2.44,7,5.3.2,11.52.11,17.58,1.18,25.29,4.43,20.57,35.13,43.46,45a7.28,7.28,0,0,0,4.39-1.89c-1.11,3.78-4.37,4.67-7,5.05a16.38,16.38,0,0,1-8-1.44c-2.79-1.34-10.7-6-12.83-8.05-12.84-12.18-7.82-29.21-37-30.38-3.07-.21-2.76,1.87-3.55,1.83-5.11-.47-9.4-.91-14.51-1.46a3.23,3.23,0,0,1-.71-.22C405.15,143,395,157.15,382.6,170.1c-5.3,5.56-10.4,10.82-15.6,8.34Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M367.41,177.19c-4.84-2.61-5.45-8.2-3.49-12.11,6.49-6.78,14.3-14.48,23.47-21,8.83-6.27,19.56-10.3,40-14.85.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.56,3.4,2.45,7,5.3.19,11.52.11,17.58,1.17,25.33,4.41,20,35.06,42.88,44.91,2.21-.18,2.69-.67,4.31-1.77-1.29,3.28-4.27,4-6.51,4.23a15.64,15.64,0,0,1-7.79-1.52c-2.77-1.36-10.82-6.07-12.94-8.09-12.85-12.17-7-28.68-36.92-29.62-3.22-.21-2.83,1.93-3.47,1.89-5.11-.5-9.61-1-14.71-1.51a1.62,1.62,0,0,1-.57-.23C404.71,142.91,395,156.17,382.62,169c-5.34,5.52-9.89,10.53-15.21,8.16Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M367.82,175.94c-4.67-2.17-5.41-7.58-3-11.39a132.82,132.82,0,0,1,22.65-20.28c8.82-6.27,19.53-10.39,40-15,.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.57,3.4,2.46,7,5.3.19,11.52.12,17.57,1.17,25.38,4.39,19.42,35,42.31,44.84,2.11-.07,2.57-.57,4.23-1.65a6.83,6.83,0,0,1-6,3.41,15.07,15.07,0,0,1-7.53-1.59c-2.76-1.4-10.95-6.12-13.07-8.13-12.85-12.18-6.2-28.17-36.84-28.87-3.38-.21-2.91,2-3.4,2-5.1-.52-9.81-1-14.92-1.57a.87.87,0,0,1-.43-.23c-23.53,2.46-32.67,14.87-45.16,27.63-5.37,5.49-9.39,10.24-14.82,8Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                        </g>
                        <path d="M368.22,174.69c-4.49-1.74-5.36-6.95-2.51-10.67,5.66-6.93,12.64-13.05,21.81-19.56,8.83-6.27,19.52-10.48,39.91-15.22.52-3.7,1.66-6.83,3.69-6.61L443.45,124c2,.22,2.58,3.4,2.47,7,5.29.19,11.52.12,17.57,1.17,25.41,4.37,18.84,34.92,41.73,44.77,2,0,2.44-.47,4.15-1.52a6.55,6.55,0,0,1-5.54,2.57,14.23,14.23,0,0,1-7.29-1.65c-2.75-1.43-11.06-6.16-13.19-8.17C470.51,156,478,140.5,446.59,140c-3.53-.21-3,2.05-3.32,2l-15.13-1.62c-.11,0-.2-.09-.29-.23-24,2.45-32.62,14-45.19,26.68-5.41,5.46-8.88,10-14.44,7.81Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                        <path d="M363,194.52l-16.27-23.18c9.64-3.91,18.18-12.05,25.78-19.12a111.73,111.73,0,0,1,10.59-9.11c10-7.12,22.25-11.23,47.93-14.49l.42,12c-23.94.69-34.83,16.14-45.31,30.78-1.85,2.59-3.69,5.15-5.58,7.6-5,6.49-10.44,12.2-17.56,15.55Z" style={{fill:'url(#baf77692-d603-4e4a-9697-dbe0af5988ef)'}}/>
                        <path d="M488.05,109.8c9.89-5.16,20.39-20.58,24.2-28.35,4.54,8,10.07,11.06,10.16,16.25,0,1.5-1.52,3.65-2.65,5.19C505,123.17,461,135.44,438.15,136.12l2.5-7.54c3-1,6.4-2.15,9.6-3.19.55-.18,37.56-15.46,37.8-15.59Z" style={{fill:'url(#aa4a436d-3adb-438b-92ef-49bcbfd1554a)'}}/>
                        <path d="M498,108.9c9.86-6.58,19.43-11.82,23.09-12.14a1.29,1.29,0,0,1,1.42,1,4.36,4.36,0,0,0-.58-2.72c-2.3-4.49-9.45-15.68-11-17.09-1.09-1-2.69-.83-5-.3-14.26,3.34-30.88,18.49-40.36,29.43-12.66,14.62-16.45,17-27.39,18.63-3.49.51-6.78,1.6-6.75,1.79.19,1-6,10-1.85,10.29.34,0,5.21-1.68,11.61-2.94,33.24-6.53,25.76-5.31,56.73-26Z" style={{fill:'url(#a1edd91f-c14e-4e36-b94b-c16e4e6787b7)'}}/>
                        <path d="M450.21,130.49a17.89,17.89,0,0,0,2.84-.59c1.9-.43,3.07-.75,2.82-1.07s-2.21.27-3.14.55l-2.76.72.24.39Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                        <g id="fdfb0e72-7810-4da9-aafa-1cb850ca3a23" data-name=" 2497766625168">
                            <path d="M449.76,130.65a16.57,16.57,0,0,0,3.4-.62c2.42-.53,4.2-1.27,3.7-1.81s-2.68.61-4.24,1l-3.2.78Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M449.31,130.81a16.13,16.13,0,0,0,3.95-.65c3-.63,5.33-1.78,4.59-2.54s-3.14.94-5.34,1.47l-3.64.85c.15.3.29.59.44.87Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M448.87,131a17.82,17.82,0,0,0,4.5-.69c3.48-.73,6.46-2.29,5.47-3.27-.83-.83-3.6,1.26-6.43,1.92l-4.09.93c.18.37.36.74.55,1.11Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M448.42,131.14a19.15,19.15,0,0,0,5.06-.72c4-.84,7.58-2.81,6.35-4-1-1-4.07,1.6-7.53,2.38l-4.53,1C448,130.24,448.2,130.69,448.42,131.14Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M448,131.3a19.74,19.74,0,0,0,5.61-.76c4.55-.92,8.72-3.31,7.25-4.73-1.24-1.19-4.54,1.93-8.64,2.84l-5,1.06.75,1.59Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M447.52,131.47a21.65,21.65,0,0,0,6.17-.8c5.07-1,9.85-3.82,8.13-5.47-1.44-1.37-5,2.27-9.74,3.3l-5.41,1.13.85,1.84Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M447.08,131.63a22.57,22.57,0,0,0,6.72-.83c5.6-1.13,11-4.33,9-6.2-1.64-1.56-5.47,2.6-10.83,3.75l-5.86,1.21,1,2.07Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M446.63,131.8a24,24,0,0,0,7.27-.87c6.13-1.23,12.11-4.85,9.9-6.93-1.84-1.75-5.93,2.93-11.93,4.21l-6.3,1.27,1.06,2.32Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M446.19,132a25.37,25.37,0,0,0,7.82-.9c6.66-1.33,13.24-5.36,10.79-7.67-2.05-1.92-6.4,3.27-13,4.67L445,129.41l1.17,2.55Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M445.74,132.12a26.76,26.76,0,0,0,8.38-.93c7.18-1.43,14.36-5.87,11.67-8.4-2.25-2.11-6.87,3.59-14.14,5.12l-7.18,1.42c.43.93.85,1.86,1.27,2.79Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M445.29,132.29a28.27,28.27,0,0,0,8.93-1c7.72-1.52,15.5-6.37,12.56-9.12-2.45-2.3-7.33,3.92-15.24,5.57l-7.61,1.49,1.36,3Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M444.84,132.45a29.66,29.66,0,0,0,9.49-1c8.24-1.62,16.62-6.89,13.44-9.86-2.65-2.48-7.79,4.26-16.34,6l-8.05,1.55,1.47,3.28Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                        </g>
                        <path d="M444.4,132.61c4.61.36,7.33-.51,10-1,8.77-1.72,17.75-7.4,14.32-10.59-2.85-2.67-8.26,4.59-17.43,6.49l-8.5,1.63Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                        <path d="M442.47,126.77a8.64,8.64,0,0,0,1.83,0c1.22,0,2-.08,1.86-.37s-1.41-.16-2-.09l-1.79.1.11.34Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                        <g id="bd620dfc-274d-48de-b891-68f0d521d3d1" data-name=" 2497766563632">
                            <path d="M442.18,126.83a8.53,8.53,0,0,0,2.18.08c1.56,0,2.74-.28,2.48-.78s-1.73,0-2.74.08l-2.08.07.16.55Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M441.88,126.88a8.62,8.62,0,0,0,2.53.15c1.9,0,3.49-.49,3.1-1.2s-2.05.2-3.47.25l-2.36.06c.07.25.14.49.2.74Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M441.59,126.93a9.3,9.3,0,0,0,2.88.22c2.24,0,4.24-.7,3.72-1.62-.44-.77-2.37.38-4.2.42l-2.64,0,.24.94Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M441.29,127a9.53,9.53,0,0,0,3.23.28c2.58,0,5-.9,4.34-2-.55-1-2.69.56-4.92.59l-2.92,0c.09.38.18.76.28,1.14Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M441,127a10.52,10.52,0,0,0,3.57.34c2.93,0,5.75-1.11,5-2.45-.66-1.13-3,.74-5.66.76h-3.2c.11.45.21.9.32,1.35Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M440.7,127.09a10.92,10.92,0,0,0,3.93.4c3.26.05,6.5-1.32,5.58-2.87-.77-1.29-3.33.92-6.38.93h-3.49l.36,1.55Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M440.41,127.14a11.54,11.54,0,0,0,4.27.47c3.61,0,7.26-1.53,6.21-3.29-.88-1.47-3.66,1.1-7.12,1.1l-3.76,0c.13.58.27,1.17.4,1.75Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M440.12,127.19a12.44,12.44,0,0,0,4.62.54c3.94.06,8-1.74,6.83-3.71-1-1.65-4,1.28-7.85,1.27l-4.05-.05.45,2Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M439.82,127.25a13.3,13.3,0,0,0,5,.59c4.28.08,8.76-1.94,7.45-4.12-1.09-1.82-4.3,1.45-8.57,1.44l-4.33-.07.48,2.15Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M439.53,127.3a14,14,0,0,0,5.32.66c4.62.09,9.51-2.15,8.07-4.54-1.21-2-4.62,1.63-9.31,1.61l-4.61-.09c.18.78.35,1.57.53,2.36Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M439.24,127.35a14.64,14.64,0,0,0,5.66.72c5,.1,10.27-2.35,8.69-4.95-1.31-2.18-4.94,1.81-10,1.78l-4.89-.11.57,2.56Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                            <path d="M438.94,127.4a15.42,15.42,0,0,0,6,.79c5.29.11,11-2.56,9.31-5.37-1.43-2.35-5.27,2-10.77,2l-5.17-.13c.2.92.41,1.84.61,2.76Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                        </g>
                        <path d="M438.65,127.45a16.11,16.11,0,0,0,6.36.86c5.64.12,11.77-2.77,9.93-5.79-1.53-2.53-5.58,2.17-11.49,2.12l-5.46-.15.66,3Z" style={{fill:'#b25426',fillOpacity:0.039216}}/>
                        <path d="M346.71,171.11c9.38-3.8,17.69-11.63,25.16-18.58l.51-.48c1.9-1.76,3.73-3.46,5.5-5s3.48-2.95,5.11-4.11a61.54,61.54,0,0,1,18.16-8.73c7.53-2.32,17-4.17,29.87-5.81l0,.31c-12.83,1.63-22.29,3.47-29.8,5.79a61.1,61.1,0,0,0-18,8.8,63.44,63.44,0,0,0-5.07,4.08c-1.76,1.54-3.6,3.24-5.5,5l-.51.48c-7.49,7-16,14.9-25.46,18.73v-.48Z" style={{fill:'#ffd65f'}}/>
                        <path d="M348,102.43c.05,1.47,2.06,5.16,3.8,7.31,17,21,46.36,25.93,74.65,27.56.15-3.64.05-9,1.8-11.71-6.89-1.88-11.79-4-20.57-16.48-8.22-11.69-23-25.2-37.09-28.49-2.25-.53-6.78-1.34-9.5,2.31-1.22,1.62-10.37,12.32-12.64,16.76a4.93,4.93,0,0,0-.45,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                        <g id="bbc2009a-3224-4d22-94b2-03ed0c37c07e" data-name=" 2497766570320">
                            <path d="M348.54,102.37c0,1.47,2.09,5.11,3.81,7.22,16.71,20.49,46.06,25.76,74.06,27.6.11-3.69,0-8.85,1.82-11.53-6.88-1.88-12-3.86-20.81-16.29-8.28-11.64-23-24.9-37-28.2-2.25-.52-6.62-1.3-9.26,2.21C359.91,85,351.18,95.27,349,99.62a5,5,0,0,0-.45,2.75Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M349.09,102.3c.05,1.47,2.1,5.06,3.8,7.13,16.44,20,45.77,25.61,73.49,27.66.06-3.74.05-8.75,1.85-11.35-6.89-1.88-12.16-3.73-21.07-16.11-8.34-11.59-22.89-24.6-37-27.9-2.25-.52-6.47-1.26-9,2.1-1.24,1.6-9.54,11.45-11.65,15.73a5,5,0,0,0-.44,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M349.63,102.23c.05,1.47,2.13,5,3.81,7,16.16,19.51,45.47,25.45,72.91,27.71,0-3.79,0-8.65,1.87-11.17-6.88-1.88-12.35-3.59-21.32-15.92-8.4-11.54-22.83-24.3-36.9-27.59-2.25-.53-6.31-1.24-8.77,2-1.26,1.59-9.13,11-11.15,15.2A5.12,5.12,0,0,0,349.63,102.23Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M350.17,102.17c.05,1.47,2.16,5,3.81,6.94,15.9,19,45.17,25.29,72.34,27.76,0-3.84,0-8.54,1.89-11-6.88-1.88-12.54-3.46-21.57-15.74-8.46-11.5-22.77-24-36.84-27.3-2.25-.52-6.16-1.2-8.53,1.89-1.27,1.58-8.71,10.58-10.65,14.68a5.25,5.25,0,0,0-.45,2.75Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M350.72,102.1c0,1.47,2.18,4.93,3.81,6.86,15.62,18.51,44.87,25.12,71.75,27.8-.08-3.88.05-8.44,1.92-10.79-6.88-1.89-12.72-3.33-21.82-15.56-8.52-11.45-22.7-23.7-36.77-27-2.25-.53-6-1.17-8.29,1.78C360,86.76,353,95.33,351.16,99.36a5.25,5.25,0,0,0-.44,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M351.26,102c.05,1.47,2.21,4.89,3.81,6.77,15.35,18,44.58,25,71.18,27.85-.13-3.93,0-8.33,1.94-10.61-6.88-1.88-12.91-3.18-22.07-15.36-8.58-11.41-22.64-23.41-36.71-26.7-2.25-.53-5.85-1.14-8.05,1.67-1.29,1.55-7.87,9.7-9.65,13.64a5.34,5.34,0,0,0-.45,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M351.81,102c0,1.47,2.23,4.84,3.81,6.67,15.08,17.53,44.28,24.81,70.6,27.9-.18-4,0-8.23,2-10.42-6.88-1.89-13.09-3.05-22.32-15.18-8.63-11.36-22.57-23.11-36.64-26.41-2.25-.52-5.7-1.1-7.81,1.57-1.31,1.54-7.46,9.26-9.16,13.13a5.45,5.45,0,0,0-.45,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M352.35,101.9c0,1.47,2.25,4.8,3.81,6.58,14.81,17,44,24.65,70,28-.23-4,0-8.13,2-10.24-6.88-1.88-13.28-2.91-22.57-15C396.91,99.88,383.09,88.39,369,85.09c-2.25-.52-5.54-1.07-7.56,1.47-1.33,1.52-7,8.82-8.67,12.6a5.46,5.46,0,0,0-.44,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M352.89,101.83c.05,1.48,2.28,4.76,3.81,6.5,14.55,16.53,43.69,24.49,69.45,28-.27-4.07,0-8,2-10-6.88-1.88-13.46-2.78-22.81-14.81-8.76-11.27-22.46-22.51-36.53-25.81-2.25-.52-5.38-1-7.32,1.36-1.34,1.51-6.63,8.39-8.17,12.08a5.59,5.59,0,0,0-.44,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M353.43,101.77c.05,1.47,2.31,4.71,3.82,6.4,14.27,16,43.39,24.33,68.87,28-.31-4.11,0-7.92,2-9.87-6.88-1.88-13.65-2.64-23.06-14.62-8.82-11.22-22.39-22.21-36.46-25.51-2.25-.52-5.23-1-7.08,1.25-1.36,1.51-6.22,8-7.67,11.57a5.7,5.7,0,0,0-.45,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M354,101.7c0,1.47,2.33,4.67,3.81,6.31,14,15.55,43.09,24.17,68.3,28.09-.36-4.16,0-7.81,2.05-9.68-6.88-1.88-13.84-2.51-23.31-14.44-8.88-11.17-22.33-21.91-36.4-25.21-2.25-.52-5.07-1-6.84,1.15-1.36,1.49-5.79,7.51-7.17,11a5.84,5.84,0,0,0-.44,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M354.52,101.63c.05,1.48,2.36,4.63,3.82,6.22,13.73,15.06,42.79,24,67.72,28.14-.41-4.21,0-7.71,2.08-9.5-6.88-1.88-14-2.37-23.57-14.25-8.94-11.13-22.26-21.61-36.33-24.91-2.25-.53-4.92-.94-6.6,1-1.38,1.48-5.38,7.07-6.67,10.52a6,6,0,0,0-.45,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M355.06,101.57c.05,1.47,2.38,4.58,3.82,6.13,13.46,14.56,42.5,23.85,67.15,28.19-.46-4.26,0-7.61,2.1-9.32-6.88-1.88-14.21-2.24-23.82-14.07-9-11.08-22.2-21.31-36.26-24.6-2.26-.53-4.77-.91-6.36.93a39.65,39.65,0,0,0-6.18,10,6,6,0,0,0-.44,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M355.61,101.5c0,1.47,2.4,4.53,3.82,6,13.18,14.06,42.2,23.69,66.56,28.23-.5-4.3,0-7.5,2.13-9.13-6.88-1.88-14.4-2.1-24.07-13.88-9.05-11-22.13-21-36.2-24.31-2.25-.52-4.61-.87-6.12.83a34.47,34.47,0,0,0-5.68,9.48,6.13,6.13,0,0,0-.44,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M356.15,101.44c.05,1.47,2.43,4.48,3.82,5.94,12.92,13.57,41.91,23.54,66,28.29-.55-4.36,0-7.41,2.15-8.95-6.88-1.88-14.59-2-24.32-13.7-9.11-11-22.07-20.71-36.14-24-2.25-.52-4.45-.84-5.87.73a29.43,29.43,0,0,0-5.18,9,6.26,6.26,0,0,0-.45,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M356.69,101.37c.05,1.47,2.46,4.44,3.83,5.86,12.64,13.07,41.6,23.37,65.41,28.33-.6-4.4,0-7.3,2.17-8.76-6.88-1.89-14.77-1.83-24.57-13.52-9.17-10.94-22-20.41-36.07-23.71-2.26-.52-4.3-.8-5.63.62a25.13,25.13,0,0,0-4.69,8.44,6.44,6.44,0,0,0-.45,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M357.24,101.3c0,1.47,2.48,4.4,3.82,5.77,12.37,12.58,41.31,23.21,64.84,28.38-.65-4.45,0-7.2,2.19-8.58-6.88-1.88-15-1.69-24.82-13.33-9.23-10.89-21.94-20.11-36-23.41-2.25-.52-4.14-.77-5.39.51-1.45,1.41-3.3,4.88-4.19,7.92a6.6,6.6,0,0,0-.45,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                            <path d="M357.78,101.24c.05,1.47,2.5,4.35,3.83,5.67,12.1,12.09,41,23.06,64.25,28.43-.69-4.49,0-7.1,2.22-8.39-6.88-1.88-15.14-1.56-25.07-13.14C393.72,103,381.13,94,367.06,90.69c-2.25-.52-4-.74-5.14.41-1.47,1.4-2.89,4.44-3.7,7.39a6.67,6.67,0,0,0-.44,2.75Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                        </g>
                        <path d="M358.32,101.17c.05,1.47,2.53,4.31,3.83,5.58,11.83,11.59,40.71,22.9,63.68,28.48-.74-4.54,0-7,2.24-8.21-6.88-1.88-15.33-1.42-25.32-13-9.35-10.8-21.81-19.52-35.88-22.82-2.26-.52-3.83-.71-4.91.3-1.48,1.39-2.47,4-3.2,6.88a6.82,6.82,0,0,0-.44,2.74Z" style={{fill:'#060606',fillOpacity:0.019608}}/>
                        <polygon points="363.01 194.81 346.69 171.85 346.71 171.11 346.93 171.43 363.07 194.09 363.01 194.81" style={{fill:'#faa71a'}}/>
                        <path d="M431.47,140.81c-23.66.69-34.53,15.88-44.91,30.38l-.22.31c-.92,1.28-1.83,2.56-2.78,3.85s-1.86,2.52-2.81,3.75a72.38,72.38,0,0,1-8,9,39,39,0,0,1-9.72,6.72l.05-.72a35.74,35.74,0,0,0,9.36-6.33,72.31,72.31,0,0,0,8-8.93q1.41-1.83,2.8-3.74c.91-1.24,1.84-2.54,2.77-3.85l.23-.31c10.44-14.59,21.38-29.87,45.26-30.56v.44Z" style={{fill:'#f68c1f'}}/>
                        <path d="M405.22,116.5c-13.73-4.28-51-25.6-41.15-34.7-6.15,6.43-13.2,13.64-14.23,17.52a5.71,5.71,0,0,0-.23,1.94c.3,4.38,8.75,11.78,16.61,15.32,22.86,10.28,25.48,10.82,49.62,16.73A107,107,0,0,0,434,136c-.3-1.09,3.08-3.24-1.65-5.44-.69-.33-22.07-12.49-27.14-14.06Z" style={{fill:'url(#bc1bd72a-f487-43d6-b963-9c4f2fc7b349)'}}/>
                        <path d="M352.12,99.15a2.09,2.09,0,0,0-2.51,2.11,7.75,7.75,0,0,1,1.68-6.14l10.53-12.24c8.09-9.4,39.38,16.37,45.88,24.54,7.09,8.92,8.72,15.19,22.68,17.49l-1.66,10.3c-20.44-4.09-24.09-9.35-40.67-18.78-14.56-8.27-29.62-16.79-35.93-17.28Z" style={{fill:'url(#fb4f0c36-3857-45b2-a5cc-be4a90fdfa8b)'}}/>
                        <path d="M423.72,126.83a8.72,8.72,0,0,1-2-.48c-1.36-.37-2.2-.66-2.09-1s1.54.18,2.22.44l2,.63-.1.41Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                        <g id="b829b921-4d8c-41af-8855-ce554b2445db" data-name=" 2497976030224">
                            <path d="M424.05,127a8.61,8.61,0,0,1-2.4-.5c-1.73-.45-3-1.14-2.81-1.73s1.91.52,3,.88l2.3.68-.12.67Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M424.38,127.14a8.48,8.48,0,0,1-2.78-.51c-2.1-.54-3.9-1.63-3.54-2.46s2.29.84,3.86,1.31l2.61.73-.15.93Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M424.71,127.29a8.65,8.65,0,0,1-3.16-.53c-2.47-.61-4.75-2.11-4.26-3.17.41-.9,2.65,1.16,4.67,1.73l2.92.79c-.05.39-.11.78-.17,1.18Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M425,127.44a9.19,9.19,0,0,1-3.54-.54c-2.85-.69-5.6-2.59-5-3.9.52-1.1,3,1.49,5.5,2.16l3.23.84c-.06.48-.13,1-.2,1.44Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M425.37,127.59a9.47,9.47,0,0,1-3.92-.55c-3.22-.77-6.45-3.08-5.71-4.63.62-1.29,3.4,1.82,6.32,2.59l3.54.9c-.08.56-.15,1.13-.23,1.69Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M425.7,127.74a10.13,10.13,0,0,1-4.3-.56c-3.59-.86-7.3-3.56-6.43-5.35.72-1.5,3.77,2.14,7.14,3l3.85,1c-.09.65-.17,1.3-.26,2Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M426,127.9a10.88,10.88,0,0,1-4.67-.58c-4-.94-8.17-4-7.17-6.08.83-1.7,4.14,2.47,8,3.44l4.16,1c-.09.74-.19,1.47-.28,2.2Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M426.36,128.05a11.44,11.44,0,0,1-5.05-.59c-4.35-1-9-4.54-7.9-6.81.94-1.9,4.52,2.8,8.79,3.88l4.47,1.06C426.57,126.41,426.46,127.23,426.36,128.05Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M426.69,128.2a12,12,0,0,1-5.43-.61c-4.72-1.09-9.87-5-8.62-7.53,1-2.1,4.88,3.13,9.61,4.31l4.78,1.11c-.12.91-.23,1.81-.34,2.72Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M427,128.35a12.72,12.72,0,0,1-5.81-.62c-5.1-1.18-10.72-5.5-9.34-8.25,1.14-2.3,5.26,3.45,10.43,4.73l5.08,1.17-.36,3Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M427.35,128.5c-2.73.46-4.42-.22-6.19-.63-5.47-1.26-11.57-6-10.07-9,1.25-2.5,5.63,3.78,11.25,5.16l5.4,1.23-.39,3.22Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M427.68,128.65c-2.89.52-4.73-.22-6.57-.64-5.85-1.34-12.42-6.47-10.8-9.71,1.36-2.7,6,4.11,12.08,5.59l5.71,1.28-.42,3.48Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                        </g>
                        <path d="M428,128.81c-3.05.56-5-.22-6.95-.66-6.22-1.42-13.27-7-11.52-10.43,1.46-2.91,6.38,4.43,12.9,6l6,1.33-.44,3.74Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                        <path d="M429.14,132.81a5.52,5.52,0,0,1-1.08.49c-.73.31-1.21.48-1.24.28s.77-.46,1.15-.58l1.1-.42Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                        <g id="b8ff6552-ea28-4ba8-9a08-8ade99de9bd9" data-name=" 2497976037136">
                            <path d="M429.33,132.77a5.45,5.45,0,0,1-1.26.62c-.93.4-1.73.55-1.77.19s1-.44,1.65-.67l1.26-.5.12.36Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M429.53,132.72a5.11,5.11,0,0,1-1.44.75c-1.13.5-2.26.63-2.31.11s1.29-.42,2.15-.76l1.42-.59.18.49Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M429.72,132.67a5.31,5.31,0,0,1-1.62.88c-1.32.59-2.79.7-2.84,0s1.56-.4,2.65-.85l1.58-.67c.08.2.15.41.23.61Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M429.91,132.63a5.6,5.6,0,0,1-1.8,1c-1.52.68-3.31.77-3.36-.06s1.81-.38,3.14-.94l1.74-.76c.1.25.19.5.28.75Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M430.11,132.58a6,6,0,0,1-2,1.14c-1.72.78-3.84.85-3.9-.14-.05-.83,2.07-.36,3.65-1l1.89-.83c.12.29.23.58.34.87Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M430.3,132.54a6.33,6.33,0,0,1-2.16,1.26c-1.92.88-4.37.93-4.43-.22,0-1,2.33-.34,4.15-1.13l2.05-.92.39,1Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M430.5,132.49a6.82,6.82,0,0,1-2.35,1.39c-2.11,1-4.89,1-5-.3-.05-1.09,2.6-.31,4.65-1.22l2.21-1,.45,1.13Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M430.69,132.44a6.84,6.84,0,0,1-2.53,1.52c-2.3,1.07-5.41,1.08-5.48-.38-.06-1.22,2.85-.29,5.14-1.31l2.37-1.09c.17.42.34.84.5,1.26Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M430.89,132.4a7.68,7.68,0,0,1-2.71,1.65c-2.51,1.16-5.95,1.15-6-.47-.06-1.35,3.11-.27,5.64-1.4l2.53-1.17C430.52,131.47,430.7,131.93,430.89,132.4Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M431.08,132.35a7.94,7.94,0,0,1-2.89,1.78c-2.7,1.26-6.47,1.23-6.55-.55-.07-1.49,3.37-.25,6.14-1.49l2.69-1.26c.21.51.41,1,.61,1.52Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M431.28,132.3a8.37,8.37,0,0,1-3.08,1.91c-2.89,1.36-7,1.31-7.08-.63-.06-1.62,3.64-.23,6.64-1.58l2.85-1.35.67,1.65Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                            <path d="M431.47,132.26a8.79,8.79,0,0,1-3.25,2c-3.1,1.45-7.53,1.38-7.61-.71-.08-1.75,3.89-.21,7.13-1.67l3-1.43c.23.59.47,1.19.71,1.78Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                        </g>
                        <path d="M431.67,132.21a9.11,9.11,0,0,1-3.44,2.16c-3.29,1.55-8.05,1.46-8.14-.79-.08-1.88,4.15-.19,7.63-1.76l3.17-1.52Z" style={{fill:'#cb5e27',fillOpacity:0.039216}}/>
                        <path d="M444.19,134c6.61-1.2,15.19.3,14.65,3.56-.46,2.76-7.84-.92-14.86.38l-.3.07.51-4Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444.17,134.12c6.22-1.12,14.2.25,13.7,3.28-.43,2.57-7.36-.81-13.89.4l-.28,0,.47-3.73Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444.15,134.25c5.82-1.05,13.21.2,12.75,3-.39,2.38-6.88-.7-12.93.41l-.25.05.43-3.46Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444.14,134.37c5.41-1,12.21.16,11.79,2.73-.35,2.19-6.4-.6-12,.42l-.23,0,.41-3.19Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444.12,134.5c5-.89,11.22.11,10.84,2.45-.32,2-5.92-.49-11,.43l-.2,0,.37-2.92Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444.11,134.63c4.61-.82,10.22.06,9.88,2.18-.28,1.8-5.43-.39-10,.43l-.18,0,.33-2.64Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444.09,134.75c4.22-.74,9.23,0,8.93,1.91-.25,1.61-4.95-.29-9.08.44l-.15,0,.3-2.38Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444.07,134.88c3.82-.66,8.23,0,8,1.63-.21,1.42-4.47-.18-8.12.46l-.12,0,.26-2.11Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444.06,135c3.41-.59,7.23-.07,7,1.35-.17,1.23-4-.07-7.16.47l-.1,0,.24-1.84Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444,135.13c3-.51,6.24-.12,6.08,1.09-.15,1-3.52,0-6.2.47h-.08l.2-1.57Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444,135.26c2.6-.44,5.24-.17,5.11.81s-3,.13-5.23.48h-.05l.17-1.3Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444,135.39c2.2-.37,4.25-.22,4.17.53s-2.56.24-4.28.49h0l.13-1Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444,135.51c1.8-.29,3.26-.26,3.22.26s-2.07.35-3.31.5h0l.09-.75Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M444,135.64c1.38-.22,2.26-.31,2.26,0s-1.56.44-2.32.5l.06-.49Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M522.48,97.29a5.87,5.87,0,0,0-.2-1.09,3.89,3.89,0,0,0-.34-.94c-1.15-2.26-3.71-6.29-6-9.84a70.19,70.19,0,0,0-5-7.3,2.64,2.64,0,0,0-1.9-.68,11.81,11.81,0,0,0-2.88.43c-6.25,1.46-13.09,5.16-19.46,9.74-8.11,5.81-15.6,13.55-20.89,19.66-6.34,7.32-10.47,11.59-14.38,14.23s-7.64,3.66-13.14,4.47l-.06-.41c5.44-.8,9.1-1.8,13-4.41s8-6.86,14.29-14.16c5.32-6.13,12.83-13.89,21-19.72,6.42-4.61,13.14-8.18,19.45-9.66a13,13,0,0,1,3-.44,3.13,3.13,0,0,1,2.18.79,57.3,57.3,0,0,1,5.22,7.23c2.28,3.56,4.68,7.53,5.84,9.81a4.16,4.16,0,0,1,.37,1,7.41,7.41,0,0,1,.21,1.33v.21l-.25-.29Z" style={{fill:'#fde284'}}/>
                        <path d="M522.66,97.38c0,.06,0,.17,0,.29s0,.34,0,.42a7,7,0,0,1-1,2.51c-.5.85-1.12,1.7-1.64,2.41-6.55,9-18.78,16.39-32.38,21.93-17.2,7-36.6,11-49.4,11.39v-.42c12.75-.38,32.09-4.38,49.26-11.36,13.53-5.51,25.69-12.87,32.19-21.78.52-.71,1.12-1.54,1.62-2.38a6.79,6.79,0,0,0,1-2.35c0-.07,0-.24,0-.4s0-.22,0-.25Z" style={{fill:'#b35126'}}/>
                        <path d="M441.24,134.59c26.75-5.25,27.07-5.48,42.38-16.18,3.7-2.58,8.26-5.77,14.24-9.77a164.93,164.93,0,0,1,14.3-8.65c3.94-2.07,7.13-3.38,8.94-3.54a1.59,1.59,0,0,1,.69.09,1.37,1.37,0,0,1,.58.38c.68.7.24,1.27,0,2,.13-.73.34-2-1.25-1.87s-4.83,1.44-8.69,3.47a164.47,164.47,0,0,0-14.25,8.61c-6,4-10.55,7.19-14.23,9.76-15.43,10.79-15.74,11-42.62,16.29l-.12-.62Z" style={{fill:'#f68c1f'}}/>
                        <path d="M437.76,140.52l-2.14-11c6.17,2.45,17.81.82,28.93,2.73,27.23,4.7,27.7,33,52.79,43.19L504,185.73l-6.85,0c-25.06-9.84-19.33-45.59-59.4-45.25Z" style={{fill:'url(#a62c07e5-22f4-4589-b76c-21f479ab3336)'}}/>
                        <path d="M441,134.49c7.21-1.09,16.47.81,15.78,4.32-.59,3-8.47-1.24-16.13-.06l-.33.06.68-4.32Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M441,134.62c6.78-1,15.39.73,14.75,4-.55,2.77-7.95-1.11-15.08,0l-.3.06.63-4Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M441,134.76c6.34-.94,14.31.65,13.73,3.67-.51,2.57-7.44-1-14,0l-.28.05.59-3.74Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M441,134.9c5.9-.88,13.24.56,12.7,3.34-.46,2.36-6.92-.86-13,.06l-.24,0Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M441,135c5.46-.81,12.16.48,11.67,3-.41,2.15-6.4-.73-11.95.1l-.22,0Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.93,135.17c5-.73,11.09.4,10.66,2.68-.37,2-5.89-.59-10.91.15l-.19,0,.44-2.86Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.91,135.31c4.6-.67,10,.32,9.63,2.35-.32,1.74-5.37-.47-9.87.19l-.16,0Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.89,135.45c4.16-.6,8.93.23,8.6,2-.27,1.53-4.84-.34-8.82.23l-.14,0,.36-2.27Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.87,135.58c3.72-.53,7.85.16,7.58,1.7-.24,1.32-4.33-.21-7.78.27l-.11,0,.31-2Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.85,135.72c3.28-.46,6.77.07,6.55,1.37-.19,1.11-3.81-.08-6.74.31l-.08,0,.27-1.7Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.83,135.86c2.84-.4,5.69,0,5.52,1-.14.9-3.29,0-5.69.35h-.05Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.81,136c2.4-.32,4.61-.09,4.5.72s-2.78.17-4.65.39h0l.18-1.12Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.78,136.13c2-.26,3.54-.18,3.48.38s-2.26.31-3.6.44h0l.12-.82Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M440.76,136.27c1.51-.2,2.46-.26,2.45,0s-1.7.44-2.53.48l.08-.53Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M351.14,99.4a1.54,1.54,0,0,0-1.2,1.09,3.19,3.19,0,0,0,0,.75c.14,2.05,2.19,4.83,5.14,7.56a44.21,44.21,0,0,0,11.29,7.49c22.33,10,25.33,10.79,48,16.34l1.56.38a114.78,114.78,0,0,0,15.92,2.55l1.61.13c.18,0,.24.46.25.29l.31,0,.32,0V136c0,.2-.34.31-.92.27l-1.62-.12a116.83,116.83,0,0,1-16-2.57l-1.55-.38c-22.73-5.57-25.74-6.3-48.12-16.37a44.9,44.9,0,0,1-11.45-7.61c-3.06-2.83-5.19-5.76-5.35-8a2.21,2.21,0,0,1,1.6-2.26l.26.38Z" style={{fill:'#b35126'}}/>
                        <path d="M349.29,101.28a8.56,8.56,0,0,1,.39-3.47,9.13,9.13,0,0,1,1.68-2.92l10.46-12a5.77,5.77,0,0,1,.83-.81c3.33-2.65,9.06-1.39,15.64,2a111.62,111.62,0,0,1,19.39,13.3A81.54,81.54,0,0,1,408,107.23c1.34,1.69,2.49,3.29,3.57,4.79,4.54,6.31,7.68,11,18.86,12.9l-.05.31c-11.43-1.89-14.68-6.41-19.31-12.84-1.07-1.49-2.22-3.07-3.57-4.77a80.12,80.12,0,0,0-10.18-9.79A111,111,0,0,0,378,84.61c-6.37-3.26-12.1-4.72-15.2-2.25a5,5,0,0,0-.74.72L351.53,95.32A8.46,8.46,0,0,0,350,98a7.72,7.72,0,0,0-.34,2.41l-.34.84Z" style={{fill:'#fde284'}}/>
                        <path d="M435.69,129.37c3.31,1.31,8.25,1.44,13.84,1.58A105.16,105.16,0,0,1,464.58,132c14.72,2.53,21.65,11.92,28.83,21.65,6.14,8.31,12.46,16.88,24,21.55l-.16.39c-11.66-4.72-18-13.33-24.19-21.69-7.12-9.66-14-19-28.56-21.49a105,105,0,0,0-15-1.24c-5.64-.15-10.61-.27-14-1.62l.16-.21Z" style={{fill:'#ffce46'}}/>
                        <path d="M428.63,135.62c-15.33-3.07-21.26-6.8-30.22-12.43-3-1.9-6.38-4-10.57-6.39-7.27-4.14-14.67-8.33-21-11.55s-11.62-5.44-14.71-5.68c-1.93-.14-2.69.74-2.63,2.63a2.68,2.68,0,0,1,.49-2.8,2.73,2.73,0,0,1,2.15-.67h0c3.22.25,8.61,2.52,15,5.77s13.79,7.43,21.08,11.57c4.2,2.39,7.57,4.51,10.6,6.41,8.88,5.59,14.75,9.28,29.94,12.32l-.17.82Z" style={{fill:'#f68c1f'}}/>
                        <path d="M427,132.09a7.79,7.79,0,0,1-1.45,0c-1,0-1.58-.09-1.47-.31s1.11-.11,1.59-.05l1.43.1-.1.27Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                        <g id="e9b4e247-93f1-47e6-a034-7c2a63c45b83" data-name=" 2497819058448">
                            <path d="M427.25,132.13a6.76,6.76,0,0,1-1.73,0c-1.24,0-2.17-.26-2-.65s1.37,0,2.17.1l1.65.08c0,.15-.08.29-.13.43Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M427.48,132.18a7,7,0,0,1-2,.08c-1.51,0-2.76-.43-2.44-1s1.62.19,2.75.25l1.87.07c-.06.19-.11.39-.17.59Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M427.72,132.22a7.43,7.43,0,0,1-2.29.14c-1.78,0-3.36-.61-2.93-1.33s1.87.33,3.32.38l2.1.06c-.07.25-.13.5-.2.75Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M428,132.27a7.86,7.86,0,0,1-2.56.18c-2.05,0-4-.78-3.43-1.67s2.13.48,3.91.53l2.32.05Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M428.18,132.31a8.17,8.17,0,0,1-2.84.23c-2.32,0-4.55-.95-3.91-2,.54-.88,2.38.63,4.48.67l2.54,0-.27,1.07Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M428.42,132.36a8.75,8.75,0,0,1-3.12.27c-2.59,0-5.15-1.13-4.4-2.35.62-1,2.63.77,5.06.82l2.76,0-.3,1.23Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M428.65,132.4a9.15,9.15,0,0,1-3.4.32c-2.86,0-5.74-1.3-4.89-2.68.72-1.16,2.89.91,5.64,1h3c-.11.47-.23.93-.34,1.39Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M428.88,132.45a10,10,0,0,1-3.67.37c-3.13,0-6.34-1.48-5.38-3,.81-1.3,3.14,1.06,6.21,1.11h3.22c-.13.52-.25,1-.38,1.55Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M429.11,132.49a10.42,10.42,0,0,1-3.95.42c-3.4,0-6.93-1.65-5.86-3.37.89-1.43,3.39,1.21,6.79,1.25h3.44l-.42,1.7Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M429.35,132.54a11,11,0,0,1-4.23.46c-3.67,0-7.53-1.82-6.36-3.7,1-1.58,3.65,1.35,7.37,1.39l3.67,0-.45,1.87Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M429.58,132.58a11.51,11.51,0,0,1-4.51.51c-3.93,0-8.12-2-6.84-4,1.07-1.71,3.91,1.5,7.95,1.53l3.89,0c-.16.68-.33,1.36-.49,2Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                            <path d="M429.81,132.63a12.29,12.29,0,0,1-4.78.55c-4.21,0-8.72-2.17-7.33-4.38,1.16-1.85,4.15,1.65,8.52,1.68l4.11,0c-.17.73-.34,1.46-.52,2.19Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                        </g>
                        <path d="M430.05,132.67a12.82,12.82,0,0,1-5.07.6c-4.47,0-9.31-2.34-7.81-4.72,1.25-2,4.41,1.8,9.1,1.83l4.33,0-.55,2.34Z" style={{fill:'#b25426',fillOpacity:0.031373}}/>
                        <path d="M525.38,169.16c-2.31,3.93-20.12,11.27-23.48,10.58-1.59,6.79-3.92,6.79-8.47,4.26,7.19,4.84,16.12,2.36,22.7-2.19C521.64,178,525.5,172.78,525.38,169.16Z" style={{fill:'url(#aabf5e79-007f-48d9-b60b-42769324c3ea)'}}/>
                        <path d="M525.38,169.16c-1.12,1.9-5.34,4.68-10.17,6.86-5.24,2.37-10.9,4.22-13.06,4.09l-.58-.6c2.94.6,10.65-2.87,13.37-4.09,4.73-2.13,8.83-4.83,9.86-6.6l.58.34Z" style={{fill:'#fde389'}}/>
                        <path d="M495,185c-9-4.28-13.68-12-18.51-19.88-7.49-12.2-15.12-24.62-38.75-24.42v-.42c23.87-.2,31.56,12.33,39.1,24.62,4.58,7.45,9.1,14.81,17.16,19.16,1.39.75,3.05,1.81,4.66,1.29s2.52-4.29,2.88-5.87l.59.6c-.81,3.32-2,5.09-3.4,5.65-1.09.43-2.69-.23-3.74-.73Z" style={{fill:'#f68c1f'}}/>
                        <path d="M432.57,134.69c-7.19-1.24-16.49.47-15.86,4,.52,3,8.49-1.06,16.12.28l.33.07-.59-4.34Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.59,134.83c-6.75-1.16-15.41.4-14.83,3.68.48,2.79,8-.94,15.07.3l.31.06Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.61,135c-6.32-1.09-14.33.34-13.8,3.37.44,2.58,7.45-.83,14,.32l.28.06-.51-3.75Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.63,135.1c-5.89-1-13.25.29-12.77,3.07.4,2.37,6.93-.7,13,.34l.24.05-.47-3.46Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.64,135.24c-5.45-.92-12.16.23-11.73,2.76.36,2.16,6.41-.59,11.94.36l.22,0-.43-3.16Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.66,135.38c-5-.85-11.09.17-10.7,2.45.32,1.95,5.89-.47,10.9.38l.19,0-.39-2.87Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.68,135.52c-4.58-.77-10,.1-9.67,2.15.28,1.74,5.37-.36,9.86.39l.16,0-.35-2.57Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.7,135.65c-4.14-.68-8.93,0-8.64,1.85.24,1.53,4.85-.24,8.81.41l.14,0-.31-2.29Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.72,135.79c-3.71-.61-7.85,0-7.61,1.54.2,1.32,4.33-.12,7.77.43l.11,0-.27-2Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.74,135.93c-3.27-.53-6.77-.07-6.58,1.23.16,1.11,3.81,0,6.73.45l.08,0-.23-1.7Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.76,136.06c-2.84-.45-5.69-.13-5.55.93.12.91,3.29.12,5.68.47H433Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.77,136.2c-2.39-.37-4.61-.19-4.51.62s2.77.24,4.64.49h0Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.79,136.34c-2-.3-3.53-.25-3.48.31s2.25.35,3.59.51h0l-.11-.82Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M432.81,136.48c-1.5-.23-2.45-.32-2.45,0s1.69.47,2.52.53l-.07-.53Z" style={{fill:'#a84a24',fillOpacity:0.039216}}/>
                        <path d="M425.5,126.6c-4,1.43,1.77,14.93,3.15,14.44l12.62-4.5c1.38-.49-1.64-15-5.63-13.55Z" style={{fill:'url(#b798159d-df65-45d5-b9ec-bd665645ca5c)'}}/>
                        <path d="M425.7,126.53c-3.73,1.33,2.08,14.82,3.37,14.36l11.78-4.2c1.29-.45-1.82-14.9-5.55-13.58l-9.61,3.42Z" style={{fill:'url(#b0ae4ec3-5689-414a-8466-505761074efb)'}}/>
                        <path d="M429.85,122.5c-5.39-.38-4.61,18.57-2.75,18.7l17,1.21c1.86.13,4.78-18.56-.61-18.94l-13.65-1Z" style={{fill:'url(#a18d2d0c-d74d-49cf-9f1a-eb9448e991aa)'}}/>
                        <path d="M430.11,122.52c-5-.35-4.17,18.6-2.44,18.72l15.87,1.13c1.73.12,4.53-18.57-.49-18.93l-12.94-.92Z" style={{fill:'url(#e8ef3103-d01e-40e2-b81d-d11082d2934c)'}}/>
                    </g>
                </g>
            </g>
        </svg>
    )
})

VoucherEventLogo.defaultProps = {
    x: 0,
    y: 0,
    width: '304px'
}

export default withTheme(themes)(VoucherEventLogo)
