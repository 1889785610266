
        import i18n from 'i18n';
        import flattenObject from 'util/flattenObject';
    
            import ns5018014d76dcba6bf04ec4fe258f66a8en_US from './en-US.yaml'
            i18n.addResourceBundle('en-US', '5018014d76dcba6bf04ec4fe258f66a8', flattenObject(ns5018014d76dcba6bf04ec4fe258f66a8en_US), true, true);
        
            import ns5018014d76dcba6bf04ec4fe258f66a8en from './en.yaml'
            i18n.addResourceBundle('en', '5018014d76dcba6bf04ec4fe258f66a8', flattenObject(ns5018014d76dcba6bf04ec4fe258f66a8en), true, true);
        
        export default '5018014d76dcba6bf04ec4fe258f66a8';
    